import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')
];

export const server_loads = [0,2,6,7,8,11];

export const dictionary = {
		"/(app)": [~17,[2],[3]],
		"/(app)/403": [18,[2],[3]],
		"/(app)/admin": [~20,[2,5],[3]],
		"/(app)/admin/customers": [~21,[2,5],[3]],
		"/(app)/admin/customers/new": [~26,[2,5],[3]],
		"/(app)/admin/customers/[straikerId]/edit": [~22,[2,5,6],[3]],
		"/(app)/admin/customers/[straikerId]/users": [~23,[2,5,6],[3]],
		"/(app)/admin/customers/[straikerId]/users/new": [~25,[2,5,6],[3]],
		"/(app)/admin/customers/[straikerId]/users/[email]/edit": [~24,[2,5,6],[3]],
		"/(app)/applications": [~27,[2],[3]],
		"/(app)/applications/[id]": [~28,[2,7],[3]],
		"/(app)/applications/[id]/detections": [29,[2,7],[3]],
		"/(app)/applications/[id]/users": [~30,[2,7,8],[3]],
		"/(app)/applications/[id]/users/[userId]": [~31,[2,7,8],[3]],
		"/(app)/kitchen-sink": [~32,[2,9],[3]],
		"/(app)/kitchen-sink/badge": [33,[2,9],[3]],
		"/(app)/kitchen-sink/button": [34,[2,9],[3]],
		"/(app)/kitchen-sink/card": [35,[2,9],[3]],
		"/(app)/kitchen-sink/hex-grid": [36,[2,9],[3]],
		"/(app)/kitchen-sink/hexagon": [37,[2,9],[3]],
		"/(app)/kitchen-sink/logo": [38,[2,9],[3]],
		"/(app)/kitchen-sink/sheet": [39,[2,9],[3]],
		"/(app)/kitchen-sink/switch": [40,[2,9],[3]],
		"/(app)/kitchen-sink/tag": [41,[2,9],[3]],
		"/(unprotected)/login": [~61,[14,15]],
		"/(unprotected)/login/password": [~62,[14,15,16]],
		"/(unprotected)/login/password/forgot": [~63,[14,15,16]],
		"/(unprotected)/logout": [64,[14]],
		"/(app)/profile": [42,[2],[3]],
		"/(app)/settings": [~43,[2,10],[3]],
		"/(app)/settings/applications": [~44,[2,10],[3]],
		"/(app)/settings/applications/new": [47,[2,10],[3]],
		"/(app)/settings/applications/[id]": [45,[2,10],[3]],
		"/(app)/settings/applications/[id]/edit": [~46,[2,10],[3]],
		"/(app)/settings/detections": [~48,[2,10],[3]],
		"/(app)/settings/integrations": [49,[2,10,11],[3]],
		"/(app)/settings/integrations/slack": [51,[2,10,11,12],[3]],
		"/(app)/settings/integrations/slack/new": [~53,[2,10,11,12],[3]],
		"/(app)/settings/integrations/slack/[id]/edit": [~52,[2,10,11,12],[3]],
		"/(app)/settings/integrations/webhook": [54,[2,10,11,13],[3]],
		"/(app)/settings/integrations/webhook/new": [~56,[2,10,11,13],[3]],
		"/(app)/settings/integrations/webhook/[id]/edit": [~55,[2,10,11,13],[3]],
		"/(app)/settings/integrations/[id]": [50,[2,10,11],[3]],
		"/(app)/settings/users": [~57,[2,10],[3]],
		"/(app)/settings/users/new": [~59,[2,10],[3]],
		"/(app)/settings/users/[email]/edit": [~58,[2,10],[3]],
		"/(app)/tos": [60,[2],[3]],
		"/(app)/[...catchall]": [~19,[2],[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';